
import { defineComponent, onMounted, reactive, ref, watch } from 'vue';

import UserService from '@/services/user.service';
import CreateUserRequest from '@/requests/create-user.request';
import useEmitter from '@/compostables/useEmitter';

import Loader from '@/components/Loader.vue';
import SVG from '@/components/SVG.vue';
import Input from '@/components/form/Input.vue';
import Select from '@/components/form/Select.vue';
import Checkbox from '@/components/form/Checkbox.vue';

export default defineComponent({
	props: ['active', 'roles'],

	emits: ['update:active', 'add'],

	components: {
		Loader,
		SVG,
		Input,
		Select,
		Checkbox,
	},

	setup(props, { emit }) {
		const emitter = useEmitter();
		const errorMessage = ref('');
		const showError = ref(false);

		const form: CreateUserRequest = reactive({
			first_name: '',
			last_name: '',
			email: '',
			phone: '',
			password: '',
			password_confirmation: '',
			role_id: 2,
			wants_emails: true,
		});

		const loading = ref(false);

		onMounted(async () => {
			emitter.on('escape', () => {
				emit('update:active', false);
			});
		});

		watch(
			() => props.active,
			(active) => {
				if (!active) {
					clearFields();
				}
			}
		);

		async function add() {
			if (loading.value) {
				return;
			}

			if (form.role_id != 3) {
				if (!form.password || form.password?.length < 8) {
					errorMessage.value = 'Loyniorðið skal vera minst 8 stavir';
					showError.value = true;
					return;
				}

				if (form.password !== form.password_confirmation) {
					errorMessage.value = 'Loyniorðini skulu vera líka';
					showError.value = true;
					return;
				}
			}

			errorMessage.value = '';
			showError.value = false;
			loading.value = true;

			const data: CreateUserRequest = {
				first_name: form.first_name,
				last_name: form.last_name,
				email: form.email,
				phone: form.phone,
				role_id: form.role_id,
				wants_emails: form.wants_emails,
			};

			if (form.password) {
				data.password = form.password;
			}

			if (form.password_confirmation) {
				data.password_confirmation = form.password_confirmation;
			}

			await UserService.create(data)
				.then((response) => {
					if (response.data?.errors) {
						for (const [key] of Object.entries(response.data.errors)) {
							errorMessage.value = response.data.errors[key][0];
						}

						showError.value = true;
						return;
					}

					emit('add', response.data);

					emit('update:active', false);

					clearFields();
				})
				.finally(() => {
					loading.value = false;
				});
		}

		function clearFields() {
			setTimeout(() => {
				form.first_name = '';
				form.last_name = '';
				form.email = '';
				form.phone = '';
				form.password = '';
				form.password_confirmation = '';
				form.role_id = 2;
				form.wants_emails = true;
			}, 200);
		}

		return {
			// data
			form,
			loading,
			errorMessage,
			showError,

			// functions
			add,
		};
	},
});
