
import { defineComponent, onMounted, reactive, ref, watch } from 'vue';

import UserService from '@/services/user.service';
import LinkService from '@/services/user-link.service';
import CreateUserRequest from '@/requests/create-user.request';
import UpdateUserRequest from '@/requests/update-user.request';
import useEmitter from '@/compostables/useEmitter';

import Loader from '@/components/Loader.vue';
import SVG from '@/components/SVG.vue';
import Input from '@/components/form/Input.vue';
import Select from '@/components/form/Select.vue';
import Checkbox from '@/components/form/Checkbox.vue';
import Store from '@/store';
import AuthService from '@/services/auth.service';
import User from '@/models/user.model';

export default defineComponent({
	props: ['active', 'user', 'roles'],

	emits: ['update:active', 'update'],

	components: {
		Loader,
		SVG,
		Input,
		Select,
		Checkbox,
	},

	setup(props, { emit }) {
		const user = Store.get('user');
		const emitter = useEmitter();
		const errorMessage = ref('');
		const showError = ref(false);
		const showUserLink = ref(false);
		const copyMessageOpen = ref(false);
		const copyMessage = ref('');
		const userLink = ref('');
		const userLinkId = ref(0);

		const form: CreateUserRequest = reactive({
			first_name: '',
			last_name: '',
			email: '',
			phone: '',
			password: '',
			password_confirmation: '',
			role_id: null,
			wants_emails: false,
		});

		const loading = ref(false);
		const loadingLink = ref(false);

		onMounted(async () => {
			emitter.on('escape', () => {
				emit('update:active', false);
			});
		});

		watch(props, async (propsValue) => {
			if (propsValue.user !== null) {
				let userRole = null;

				props.roles.forEach((role: { key: number; value: string }) => {
					if (role.value === propsValue.user.role_names[0]) {
						userRole = role.key;
					}
				});

				form.first_name = propsValue.user.first_name;
				form.last_name = propsValue.user.last_name;
				form.email = propsValue.user.email;
				form.phone = propsValue.user.phone;
				form.role_id = userRole;
				form.wants_emails = !!propsValue.user.wants_emails;

				if (propsValue.user.link) {
					const url = `${process.env.VUE_APP_URL}/link/${propsValue.user.link.uuid}`;

					showUserLink.value = true;
					userLink.value = url;
					userLinkId.value = propsValue.user.link.id;
				}
				return;
			}

			showError.value = false;
			showUserLink.value = false;
			copyMessageOpen.value = false;
		});

		function copyToClickBoard() {
			navigator.clipboard
				.writeText(userLink.value)
				.then(() => {
					copyMessageOpen.value = true;
					copyMessage.value = 'Leinkjan er kopiera';

					setTimeout(() => {
						copyMessageOpen.value = false;
					}, 2000);
				})
				.catch(() => {
					copyMessageOpen.value = true;
					copyMessage.value = 'Tað eydnaðist ikki at kopiera leinkjuna';

					setTimeout(() => {
						copyMessageOpen.value = false;
					}, 2000);
				});
		}

		async function add() {
			if (loading.value) {
				return;
			}

			loading.value = true;

			const data: UpdateUserRequest = {
				first_name: form.first_name,
				last_name: form.last_name,
				email: form.email,
				phone: form.phone,
				role_id: form.role_id,
				wants_emails: form.wants_emails,
			};

			if (form.role_id != 3) {
				if (!form.password || form.password?.length < 8) {
					errorMessage.value = 'Loyniorðið skal vera minst 8 stavir';

					showError.value = true;

					loading.value = false;

					return;
				}

				if (form.password != form.password_confirmation) {
					errorMessage.value = 'Loyniorðini skulu vera líka';

					showError.value = true;

					loading.value = false;

					return;
				}
			}

			if (form.password) {
				data.password = form.password;
			}

			if (form.password_confirmation) {
				data.password_confirmation = form.password_confirmation;
			}

			await UserService.update(props.user.id, data)
				.then((response) => {
					if (response?.status === 'fail') {
						errorMessage.value = response.message;
						showError.value = true;
						return;
					}

					emit('update', response);

					emit('update:active', false);

					setTimeout(() => {
						form.first_name = '';
						form.last_name = '';
						form.email = '';
						form.phone = '';
						form.password = '';
						form.password_confirmation = '';
						form.role_id = null;
						form.wants_emails = false;
					}, 200);
				})
				.finally(() => {
					loading.value = false;
				});
		}

		async function handleLink(shouldDelete: boolean) {
			loadingLink.value = true;

			if (shouldDelete && userLinkId.value) {
				await LinkService.delete(userLinkId.value).then(async (linkResponse) => {
					if (linkResponse) {
						loadingLink.value = false;
						showUserLink.value = false;
						userLinkId.value = 0;
						userLink.value = '';

						if (user.value.can('view users')) {
							await UserService.get().then((response: User[]) => {
								if (response) {
									Store.set('users', response);
								}
							});
						}

						await AuthService.me();
					}
				});
				return;
			}

			await LinkService.create(props.user.id).then(async (linkResponse) => {
				if (linkResponse) {
					loadingLink.value = false;
					showUserLink.value = true;
					userLinkId.value = Number(linkResponse.id);
					userLink.value = linkResponse.uuid ? `${process.env.VUE_APP_URL}/link/${linkResponse.uuid}` : '';

					if (user.value.can('view users')) {
						await UserService.get().then((response: User[]) => {
							if (response) {
								Store.set('users', response);
							}
						});
					}

					await AuthService.me();
				}
			});
		}

		return {
			// data
			form,
			loading,
			loadingLink,
			errorMessage,
			showError,
			showUserLink,
			userLink,
			copyMessage,
			copyMessageOpen,

			// functions
			copyToClickBoard,
			add,
			handleLink,
		};
	},
});
