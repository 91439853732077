import axios from 'axios';

import Link from '@/models/link.model';
import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';

export default class LinkService {
	public static async create(user_id: number): Promise<Link> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/users/${user_id}/link`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at stovna eina leinkju til brúkaran`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/user-links/${id}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika leinkjuna`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}
