import axios from 'axios';

import Role from '@/models/role.model';
import Toast from '@/toast';

export default class RoleService {
	public static async get(): Promise<Role[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/roles`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta allar brúkararnar`, error);

				Toast.error('Tað eydnaðist ikki at heinta rolu');

				return false;
			});
	}
}
