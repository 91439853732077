import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center pt-12"
}
const _hoisted_2 = { class: "py-3 px-4 group relative font-bold text-blue-400 whitespace-nowrap" }
const _hoisted_3 = { class: "absolute right-0 top-1/2 transform -translate-y-1/2 flex bg-gray-50 opacity-0 group-hover:opacity-100" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "py-3 px-4 whitespace-nowrap" }
const _hoisted_6 = { class: "py-3 px-4 whitespace-nowrap text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_SVG = _resolveComponent("SVG")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_Table = _resolveComponent("Table")!
  const _component_EditUserOverlay = _resolveComponent("EditUserOverlay")!
  const _component_AddUserOverlay = _resolveComponent("AddUserOverlay")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Loader, { class: "w-8 h-8 text-blue" })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_Table, {
            key: 1,
            title: "Brúkarar",
            headings: [`${_ctx.users?.length} ${_ctx.users?.length == 1 ? 'brúkari' : 'brúkarar'}`, 'Teldupostur', 'Telefonnr.'],
            add: _ctx.$user.can('create users') ? 'Stovna brúkara' : '',
            empty: false,
            onAdd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addUserOverlay.active = true))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                return (_openBlock(), _createBlock(_component_TableRow, {
                  key: user.id,
                  onClick: (e) => _ctx.handleOpenEdit(e.target, user)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("td", _hoisted_2, [
                      _createTextVNode(_toDisplayString(_ctx.getFullName(user)) + " ", 1),
                      _createElementVNode("div", _hoisted_3, [
                        (_ctx.$user.can('delete users'))
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              onClick: _withModifiers(($event: any) => (_ctx.remove(user.id)), ["prevent","stop"]),
                              class: "w-5 h-5 p-[2px] text-red hover:text-red-700 cursor-pointer",
                              title: "Strika brúkara"
                            }, [
                              _createVNode(_component_SVG, { name: "icons/trash" })
                            ], 8, _hoisted_4))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_5, _toDisplayString(user.email), 1),
                    _createElementVNode("td", _hoisted_6, _toDisplayString(user.phone), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["headings", "add"]))
        : _createCommentVNode("", true),
      (_ctx.$user.can('update users'))
        ? (_openBlock(), _createBlock(_component_EditUserOverlay, {
            key: 2,
            active: _ctx.editUserOverlay.active,
            "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editUserOverlay.active) = $event)),
            roles: _ctx.roles,
            user: _ctx.activeUser,
            onUpdate: _ctx.updateUser
          }, null, 8, ["active", "roles", "user", "onUpdate"]))
        : _createCommentVNode("", true),
      (_ctx.$user.can('create users'))
        ? (_openBlock(), _createBlock(_component_AddUserOverlay, {
            key: 3,
            active: _ctx.addUserOverlay.active,
            "onUpdate:active": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addUserOverlay.active) = $event)),
            roles: _ctx.roles,
            onAdd: _ctx.addUser
          }, null, 8, ["active", "roles", "onAdd"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}