
import { defineComponent, onMounted, reactive, Ref, ref, watch } from 'vue';

import UserService from '@/services/user.service';
import RoleService from '@/services/role.service';
import User from '@/models/user.model';
import Store from '@/store';

import Layout from '@/components/Layout.vue';
import Table from '@/components/table/Table.vue';
import TableRow from '@/components/table/Row.vue';
import AddUserOverlay from '@/components/user/AddOverlay.vue';
import EditUserOverlay from '@/components/user/EditOverlay.vue';
import Loader from '@/components/Loader.vue';
import SVG from '@/components/SVG.vue';
import Role from '@/models/role.model';
import Toast from '@/toast';

export default defineComponent({
	components: {
		Layout,
		Loader,
		Table,
		TableRow,
		AddUserOverlay,
		EditUserOverlay,
		SVG,
	},

	setup() {
		const users = Store.get('users');
		const user = Store.get('user');
		const tableName = ref('');
		const loading = ref(true);
		const roles: Ref<Array<{ key: number; value: string }>> = ref([{ key: 0, value: '' }]);

		const activeUser: Ref<User | null> = ref(null);

		const editUserOverlay = reactive({
			active: false,
		});

		const addUserOverlay = reactive({
			active: false,
		});

		onMounted(async () => {
			if (!users.value && user.value.can('view users')) {
				await UserService.get().then((response) => {
					if (response) {
						Store.set('users', response);
					}
				});
			}

			await RoleService.get()
				.then((response) => {
					if (response) {
						roles.value = response.map((role: Role) => {
							return {
								key: role.id,
								value: role.name,
							};
						});
					}
				})
				.finally(() => {
					loading.value = false;
				});
		});

		watch(activeUser, (userValue) => {
			if (userValue !== null) {
				editUserOverlay.active = true;
			}
		});

		watch(editUserOverlay, (edit) => {
			if (!edit.active) {
				activeUser.value = null;
			}
		});

		function getFullName(user: User) {
			return `${user.first_name} ${user.last_name}`;
		}

		function addUser(user: User) {
			Store.set('users', [...users.value, user]);
			Toast.success('Stovna brúkara', 'Brúkari er stovnaður');
		}

		function updateUser(user: User) {
			const usersTemp = users.value;
			const currentUserIndex = usersTemp.findIndex((prevUser: User) => prevUser.id === user.id);
			usersTemp.splice(currentUserIndex, 1, user);
			Store.set('users', usersTemp);
			Toast.success('Dagfør brúkara', 'Brúkarin er dagførdur');
		}

		function handleOpenEdit(target: Element, user: User) {
			if (target.closest('.checkbox')) {
				return;
			}

			activeUser.value = user;
		}

		/**
		 * Remove a User
		 */
		async function remove(userId: number) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			await UserService.delete(userId).then((response) => {
				if (response) {
					Store.set(
						'users',
						users.value.filter((user: User) => user.id != userId)
					);
				}
			});
		}

		return {
			// data
			users,
			tableName,
			addUserOverlay,
			editUserOverlay,
			activeUser,
			roles,
			loading,

			// functions
			getFullName,
			addUser,
			updateUser,
			handleOpenEdit,
			remove,
		};
	},
});
